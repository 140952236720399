/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import {
  usePaymentStore,
  useProfileStore,
  useUsersStore,
} from '@/stores/RootStore';
import { defaultSubscriptionInterval } from '@/stores/SubscriptionStore';
import { authorizedApi } from '@/utils/apiUtils';

export default function useUpgradeApi() {
  const { t } = useTranslation();

  const paymentStore = usePaymentStore();
  const profileStore = useProfileStore();
  const usersStore = useUsersStore();

  // extracted as the async function could not read import.meta
  const isDev = import.meta.env.DEV;

  return async ({
    elements,
    stripe,
    billingType,
  }: {
    elements: ReturnType<typeof useElements>;
    stripe: ReturnType<typeof useStripe>;
    billingType?: 'month' | 'year';
  }) => {
    if (!stripe || !elements) {
      throw new Error(t('failure_stripe_not_initialized') ?? '');
    }

    const paymentMethodResultPromise = profileStore.profile?.paymentMethod
      ? Promise.resolve({
          paymentMethod: {
            id: profileStore.profile?.paymentMethod?.paymentMethodId,
          },
          error: undefined,
        })
      : stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement)!,
          billing_details: {
            name: profileStore.profile.name ?? '',
            email: profileStore.profile.homeEmail,
          },
        });

    const result = await paymentMethodResultPromise;

    if (!result || result.error) {
      throw new Error(
        result.error?.message || t('failure_payment_method_create') || '',
      );
    }

    const payload = {
      paymentMethodId: result.paymentMethod.id,
      billingPeriod: `${billingType ?? defaultSubscriptionInterval}ly`,
      currency: usersStore.userSeatCost?.currency?.toLowerCase(),
      ...(paymentStore.payment.couponInput
        ? { couponName: paymentStore.payment.couponInput.toUpperCase() }
        : {}),
      costDetails: `additional ${
        usersStore.userSeatCost?.seatCount ?? 0
      } seats, total ${billingType ?? defaultSubscriptionInterval}ly cost of ${
        usersStore.userSeatCost?.totalCost ?? 0
      } ${usersStore.userSeatCost?.currency?.toLowerCase() ?? ''}`,
      ...(isDev ? { stripeTest: true } : {}),
    };

    const userId = (authorizedApi.defaults.headers?.id as string) ?? '';

    await authorizedApi.post(
      `/appointments/users/${userId}/subscribe`,
      payload,
    );
  };
}
