import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import ProgressFallback from '@/components/molecules/ProgressFallback';
import { useProfileStore, useSubscriptionStore } from '@/stores/RootStore';

import routePaths from './routePaths';

function RedirectDuringOnboardingRoute({ children }: PropsWithChildren) {
  const location = useLocation();

  const profileStore = useProfileStore();
  const subscriptionStore = useSubscriptionStore();

  const lastOnboardingPage =
    Number(profileStore.profile?.lastOnboardingPage) || 0;

  const isOnUpgradePage = location.pathname === routePaths.upgrade;
  const isOnTransactionCompletedPage =
    location.pathname === routePaths.transactionCompleted;
  const isOnBillingPage = location.pathname === routePaths.billing;

  if (lastOnboardingPage >= 2 && isOnTransactionCompletedPage) {
    return <>{children}</>
  }

  if (lastOnboardingPage === 0 && location.pathname !== routePaths.calendars) {
    return <Navigate to={routePaths.calendars} replace />;
  }

  if (
    lastOnboardingPage === 1 &&
    location.pathname !== routePaths.conferencing
  ) {
    return <Navigate to={routePaths.conferencing} replace />;
  }

  if (lastOnboardingPage === 2 && location.pathname !== routePaths.billing) {
    return <Navigate to={routePaths.billing} replace />;
  }

  if (lastOnboardingPage === 2 && location.pathname === routePaths.billing) {
    if (!isOnUpgradePage && !profileStore.profile.isBusinessAdmin) {
      window.location.assign(routePaths.myEvents);

      return <ProgressFallback />;
    }

    if (
      !isOnUpgradePage &&
      subscriptionStore.subscription?.id &&
      subscriptionStore.subscription.id !== 'no-subscription-id'
    ) {
      window.location.assign(routePaths.myEvents);

      return <ProgressFallback />;
    }

    if (isOnUpgradePage && subscriptionStore.subscription.status) {
      window.location.assign(routePaths.myEvents);

      return <ProgressFallback />;
    }
  }

  if (lastOnboardingPage > 2 && !isOnTransactionCompletedPage) {
    window.location.assign(routePaths.myEvents);

    return <ProgressFallback />;
  }

  return <>{children}</>;
}

export default observer(RedirectDuringOnboardingRoute);
