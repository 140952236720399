/* eslint-disable react/jsx-props-no-spreading */

import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { yupResolver } from '@/utils/yupResolver';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-6';

import { useErrorToast } from '@/components/pages/Integrations/Commons';
import addUsersModalStore from '@/components/pages/Users/organisms/AddUsersModal/addUsersModalStore';
import steps from '@/components/pages/Users/organisms/AddUsersModal/molecules/steps';
import usersFormSchema from '@/components/pages/Users/organisms/AddUsersModal/organisms/usersFormSchema';
import useBreakpoint from '@/hooks/useBreakpoint';
import { useProfileStore, useUsersStore } from '@/stores/RootStore';

function AddUsersModal() {
  const isLargerThanBreakpoint = useBreakpoint();
  const stripe = useStripe();
  const elements = useElements();
  const usersStore = useUsersStore();
  const { profile } = useProfileStore();

  const [currentStep, setCurrentStep] = useState(steps.defaultStep);

  const showErrorToast = useErrorToast();

  const formContext = useForm({
    mode: 'onChange',
    resolver: yupResolver(usersFormSchema),
  });

  const { handleSubmit, trigger } = formContext;

  const currentStepData = useMemo(() => steps.steps[currentStep], [
    currentStep,
  ]);

  useEffect(() => {
    if (addUsersModalStore.isOpen) {
      setCurrentStep(steps.defaultStep);
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUsersModalStore.isOpen]);

  return (
    <Modal
      isOpen={addUsersModalStore.isOpen}
      onClose={addUsersModalStore.close}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size={isLargerThanBreakpoint ? '4xl' : 'full'}
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay bg="#ffffff98" />
      <FormProvider {...formContext}>
        <form
          onSubmit={handleSubmit(() =>
            currentStepData.onNextClick({
              elements,
              formContext,
              profile,
              setCurrentStep,
              stripe,
              usersStore,
              showErrorToast,
            }),
          )}
        >
          <ModalContent
            my={0}
            maxH="100%"
            h={isLargerThanBreakpoint ? undefined : '100%'}
            p={4}
          >
            {currentStepData && <currentStepData.Header />}
            <ModalBody px={isLargerThanBreakpoint ? 16 : 4}>
              {currentStepData && <currentStepData.Body />}
            </ModalBody>
            {currentStepData && (
              <currentStepData.Footer
                onNextClick={currentStepData.onNextClick({
                  elements,
                  formContext,
                  profile,
                  setCurrentStep,
                  stripe,
                  usersStore,
                  showErrorToast,
                })}
                onCancelClick={addUsersModalStore.close}
              />
            )}
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default observer(AddUsersModal);
