import { Box, CloseButton, Grid, Text } from '@chakra-ui/react';
import { createRef, useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form-6';

import UserRowInput from '@/components/pages/Users/organisms/AddUsersModal/organisms/atoms/UserRowInput';

const INIT_USERS_AMOUNT = 5;

export default function UsersForm() {
  const { control, trigger } = useFormContext();

  const { append, fields, remove, insert } = useFieldArray({
    control,
    name: 'users',
  });

  const refs = useMemo(() => fields.map(() => createRef()), [fields]);

  const onDownKeyPress = useCallback(
    (index) => {
      const nextInputRef = refs[index + 1]?.current;

      if (nextInputRef) {
        nextInputRef.focus();
      }
    },
    [refs],
  );

  const onUpKeyPress = useCallback(
    (index) => {
      const previousInputRef = refs[index - 1]?.current;

      if (previousInputRef) {
        previousInputRef.focus();
      }
    },
    [refs],
  );

  const onEnterKeyPress = useCallback(
    (index) => {
      const nextInputRef = refs[index + 1]?.current;

      if (nextInputRef) {
        nextInputRef.focus();
      } else {
        append({
          email: '',
        });
      }
    },
    [append, refs],
  );

  const onPaste = useCallback(
    (event, rowIndex) => {
      event.preventDefault();
      event.stopPropagation();

      const pastedText = (event.clipboardData || window.clipboardData)
        .getData('text')
        .trim();
      const emailAddresses = pastedText
        .replace(/([,\s\n]+)/g, (_match, group) => group.charAt(0))
        .split(/[,\s\n]/)
        .filter((emailAddress) => !!emailAddress);

      // Copied to new array to avoid mutating references
      const newUsers = fields.map((field) => ({ ...field }));

      newUsers.splice(
        rowIndex,
        emailAddresses.length,
        ...emailAddresses.map((emailAddress) => ({ email: emailAddress })),
      );

      // Have at least INIT_USERS_AMOUNT slots
      const newUsersWithEmptySlots = newUsers.concat(
        Array.from(
          {
            length: Math.max(0, INIT_USERS_AMOUNT - newUsers.length),
          },
          () => ({
            email: '',
          }),
        ),
      );

      // Remove all existing fields
      remove();

      // Insert new fields
      newUsersWithEmptySlots.forEach((user, index) => {
        insert(index, user);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, insert, remove],
  );

  useEffect(() => {
    const initUserArray = Array.from({ length: INIT_USERS_AMOUNT }, () => ({
      email: '',
    }));
    append(initUserArray);
    trigger('users');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box py={4} px={6} borderWidth={1} borderColor="gray.300">
      {fields.map((field, index) => (
        <Grid
          alignItems="center"
          columnGap={2}
          key={field.id}
          templateColumns="3ch auto 1fr"
        >
          <Text>{index + 1}.</Text>
          <CloseButton
            color="gray.200"
            _hover={{ color: 'gray.500', background: 'none' }}
            isDisabled={fields.length <= 1}
            onClick={() => remove(index)}
            size="sm"
          />
          <UserRowInput
            autoFocus={index === 0}
            name={`users[${index}].email`}
            defaultValue={field.email}
            ref={refs[index]}
            onDownKeyPress={() => onDownKeyPress(index)}
            onUpKeyPress={() => onUpKeyPress(index)}
            onEnterKeyPress={() => onEnterKeyPress(index)}
            onPaste={(event) => onPaste(event, index)}
            type="text"
            placeholder={`emailaddress${index + 1}@domain.com`}
          />
        </Grid>
      ))}
    </Box>
  );
}
